<template>
  <v-navigation-drawer
    :value="open"
    fixed
    right
    app
    hide-overlay
    disable-resize-watcher

    class="pt-0"
  >
    <v-container>
      <v-layout row wrap justify-center>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn color="primary">All Locations Overview</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-layout>
      <v-layout column>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item
            class="mt-2"
            v-for="loc in allStores"
            :key="loc.location_id"
          >
            <v-list-item-title>{{loc.location_name || loc.name}}</v-list-item-title>
            <v-list-item-action>
              <v-icon>check</v-icon>
              <!-- <v-checkbox :value="isSelectedLocation(loc)" color="red darken-3"></v-checkbox> -->
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
export default {
  data: () => {
    return {
      allStores: [{
        location_id: 2,
        location_name: 'Location 1'
      },
      {
        location_id: 3,
        location_name: 'Location 1'
      }, {
        location_id: 4,
        location_name: 'Location 1'
      }]
    }
  },
  props: {
    open: {
      default: false,
      required: true
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>
<style>
.selectedLocation {
  background: var(--v-primary-base);
}
.item-right .v-list__tile {
  flex-direction: row;
  justify-content: flex-end;
}
</style>
